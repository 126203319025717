import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CaseHeader } from "../components/case/caseHeader";
import { CaseContent } from "../components/case/caseContent";
import styles from "./coworking.module.css";

const data = [
  {
    title: "Case 1 - Parent User",
    subTitle: "Mobile device",
    description:
      "The identified target group for this project consists of parents. After careful consideration, it was determined that a mobile device would be the most suitable solution to meet their needs effectively. The primary aim was to offer parents a convenient and swift means of managing preschool matters and renting coworking spaces, granting them quick and easy access to these essential services.",
    headerImages: ["persona1.png", "persona2.png", "persona3.png"],
    images: ["coworking1.png", "coworking2.png", "coworking3.png"],
  },
  {
    title: "Case 2 - Teacher User",
    subTitle: "Tablet",
    description:
      "Next goal of the project was to assist teachers in efficiently managing their preschool groups and enhancing their overall performance in their duties. Extensive research indicated that tablets emerged as the most optimal device for this specific group of users.",
    headerImages: ["persona4.png"],
    images: ["coworking4.png", "coworking5.png"],
  },
  {
    title: "Case 3 - Admin User",
    subTitle: "Desktop",
    description:
      "The primary investor and headmaster of the kindergarten actively participated in an interview, which significantly contributed to gaining a deeper understanding of his needs and objectives. The desktop design was strategically crafted to facilitate seamless access to both the preschool and coworking sections, streamlining management tasks effectively.",
    headerImages: ["persona5.png"],
    images: ["coworking6.png", "coworking7.png"],
  },
];

const casesContent = [
  {
    header: "Goal",
    description:
      "A cross-platform system for kindergarten offering parents renting a coworking space",
  },
  {
    header: "Client",
    description: "Private kindergarten",
  },
  {
    header: "My Role",
    description:
      "Entire product design from research to conception, visualization",
  },
];

const Coworking = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.wrapper}>
      <CaseHeader cases={casesContent} />
      <CaseContent data={data} />
    </div>
  );
};
export default Coworking;
