import styles from "./rwd.module.scss";
import RWDIcon from "../assets/rwd-icon.svg";

export const RWD = () => {
  return (
    <div className={styles["rwd"]}>
      <img className={styles["rwd-icon"]} src={RWDIcon} alt="React Logo" />
      <div className={styles["rwd-text"]}>
        You may be trying to access the application through your phone or
        tablet, we currently only support desktop resolution (width at least
        1366px)
      </div>
    </div>
  );
};
