import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CaseHeader } from "../components/case/caseHeader";
import { CaseContent } from "../components/case/caseContent";
import styles from "./funCar.module.css";

const data = [
  {
    title: "Creating personas",
    description:
      "Interviews were conducted with members of FunCar to gain a deeper understanding of their needs, goals, and motivations. The application is designed for car enthusiasts who wish to explore various types of cars. Main goal was to create an enjoyable fleet browsing experience with a variety of options to help users easily find their next car rental.",
    headerImages: ["1persona.png", "2persona.png", "3persona.png"],
    images: [],
  },
  {
    title: "Design",
    description:
      "The design includes a comments section and direct contact with car owners, fostering a sense of community among users. Additionally implemented a feature to create long-term wishlists, encouraging users to remain engaged and active within the FunCar Club.",
    headerImages: [],
    images: ["funcar1.png", "funcar2.png", "funcar3.png"],
  },
];

const casesContent = [
  {
    header: "Goal",
    description: "Mobile app for club members to manage a car fleet",
  },
  {
    header: "Client",
    description: "Private investor",
  },
  {
    header: "My Role",
    description:
      "Entire product design from research to conception, visualization and support",
  },
];

const FunCar = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.wrapper}>
      <CaseHeader cases={casesContent} />
      <CaseContent data={data} />
    </div>
  );
};
export default FunCar;
