import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CaseHeader } from "../components/case/caseHeader";
import { CaseContent } from "../components/case/caseContent";
import styles from "./autoreport.module.css";

const data = [
  {
    title: "Creating personas",
    description:
      "For the project's requirements three distinct protopersonas were created, each aligned with a specific aspect of the project.",
    headerImages: [
      "protopersona1.png",
      "protopersona2.png",
      "protopersona3.png",
    ],
    images: [],
  },
  {
    title: "Landing page",
    description:
      "The project involved the creation of a visually appealing and user-friendly landing page. The design focused on simplicity and cleanliness, ensuring a seamless user experience. To enhance user engagement and capture their interest, micro animations were strategically incorporated throughout the landing page. These subtle animations not only added an element of delight and interactivity but also helped draw attention to important elements, guiding the user's attention to key messages and calls-to-action.",
    headerImages: [],
    images: ["landing.png"],
  },
  {
    title: "Design Goals",
    description:
      "Create a transparent interface with a straightforward navigation system, ensuring users can effortlessly find their way around the platform.Enable easy subscription creation and management, providing users with a seamless and hassle-free experience when signing up for services.Develop a flexible and customizable design that allows for future adjustments and modifications, ensuring the platform can adapt to evolving needs and preferences.",
    headerImages: [],
    images: [
      "autoreport1.png",
      "autoreport2.png",
      "autoreport3.png",
      "autoreport4.png",
    ],
  },
];

const casesContent = [
  {
    header: "Goal",
    description: "Web application for monitoring changes on websites",
  },
  {
    header: "Client",
    description: "Private investor",
  },
  {
    header: "My Role",
    description:
      "Product design from research to conception, visualization and contact with client",
  },
];

const Autoreport = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.wrapper}>
      <CaseHeader cases={casesContent} />
      <CaseContent data={data} />
    </div>
  );
};

export default Autoreport;
