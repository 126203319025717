import { Carousel } from "react-responsive-carousel";
import styles from "./imgCarousel.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const ImgCarousel = ({ images }: any) => {
  return (
    <Carousel
      className={styles["carousel-wrapper"]}
      showThumbs={false}
      showArrows={true}
    >
      {images.map((i: string) => {
        return (
          <div key={i}>
            <img
              className={styles.img}
              key={i}
              alt="img"
              src={require(`../assets/cases/${i}`)}
            />
          </div>
        );
      })}
    </Carousel>
  );
};
