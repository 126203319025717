import classNames from "classnames";
import styles from "./caseContent.module.css";
import { ImgCarousel } from "../imgCarousel";
import { BackToHomePage } from "../backToHomepage";

export const CaseContent = ({ data }: any) => {
  return (
    <div className={styles.wrapper}>
      {data.map((d: any) => (
        <div key={d.title} className={styles["case-wrapper"]}>
          <div key={d.title} className={styles["txt-wrapper"]}>
            <div key={d.title} className={styles.title}>
              {d.title}
            </div>
            <div key={d.subTitle} className={styles.subTitle}>
              {d.subTitle}
            </div>
            <div key={d.description} className={styles.description}>
              {d.description}
            </div>
          </div>
          <div
            className={classNames(styles["title-img-wrapper"], {
              [styles["title-img-wrapper-3"]]: d.headerImages.length === 3,
            })}
          >
            <ImgCarousel images={d.headerImages} />
          </div>
          <div className={styles["img-wrapper"]}>
            {d.images.map((i: any) => (
              <img
                key={i}
                className={styles.img}
                src={require(`../../assets/cases/${i}`)}
                alt="img"
              />
            ))}
          </div>
        </div>
      ))}
      <BackToHomePage />
    </div>
  );
};
