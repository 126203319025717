import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styles from "./backToHomepage.module.scss";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

export const BackToHomePage = () => {
  return (
    <Link to={"/"} className={styles["button"]}>
      <FontAwesomeIcon className={styles["arrow"]} icon={faArrowLeftLong} />
      <div>Back to homepage</div>
    </Link>
  );
};
