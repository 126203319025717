import styles from "./caseHeader.module.css";

export const CaseHeader = ({ cases }: any) => {
  return (
    <div className={styles["cases-wrapper"]}>
      {cases.map((c: any) => (
        <div key={c.header} className={styles["case"]}>
          <div key={c.header} className={styles["case-header"]}>
            {c.header}
          </div>
          <div key={c.description} className={styles["case-description"]}>
            {c.description}
          </div>
        </div>
      ))}
    </div>
  );
};
