import { Link, useLocation } from "react-router-dom";
import styles from "./main.module.css";
import Project3 from "../assets/project3.png";
import Project2 from "../assets/project2.png";
import Project1 from "../assets/project1.png";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import common from "../common.module.css";
import CV from "../assets/CV.pdf";
import me from "../assets/me.png";

const ProjectBox = ({
  header,
  paragraph,
  description,
  buttonColor,
  buttonColorHover,
  img,
  linkTo,
}: any) => {
  const [isHovering, setIsHovering] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles["project-wrapper"]}>
      <img src={img} className={styles["image-project"]} alt="img" />
      <div className={styles["project-description-wrapper"]}>
        <div className={styles["txt-wrapper"]}>
          <div className={styles["project-header"]}>{header}</div>
          <div className={styles["project-paragraph"]}>{paragraph}</div>
          <div className={styles["project-description"]}>{description}</div>
        </div>
        <Link
          to={linkTo}
          className={styles["project-button"]}
          style={{
            backgroundColor: isHovering ? buttonColorHover : buttonColor,
          }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          more
        </Link>
      </div>
    </div>
  );
};

const Main = () => (
  <div className={styles.wrapper}>
    <img className={styles.me} alt="me" src={me} />
    <div className={styles["txt-header"]}>Hello, I'm Olga</div>
    <div className={styles["txt-description"]}>
      A beginner UX Designer who just finished training and an internship.
      <br />
      I'm eager to transition from the learning phase and dive headfirst into real-world projects.
      <br />
      Rest assured, you won't regret having me on board for your design system needs!
    </div>
    <div className={styles["txt-header"]}>Projects</div>
    <ProjectBox
      header="ANALYSIS AND DESIGN / WEB APPLICATION"
      paragraph="Advanced tool for monitoring changes on website "
      description="Creating event by using code, defining the frequency of checking and monitored data"
      img={Project3}
      buttonColor="#AFC9C1"
      buttonColorHover="#6CAB97"
      linkTo="autoreport"
    />
    <ProjectBox
      header="ANALYSIS AND DESIGN / CROSSPLATFROM"
      paragraph="Modern approach to preschool management"
      description="Crossplatform application for kindergarten with coworking space for
          parents"
      img={Project2}
      buttonColor="#ffbcbc"
      buttonColorHover="#E6A9A9"
      linkTo="coworking"
    />
    <ProjectBox
      header="ANALYSIS AND DESIGN / MOBILE"
      paragraph="Club that shares your hobby?"
      description="Mobile appliaction for Club users to manage a car fleet."
      img={Project1}
      buttonColor="#FFE1AF"
      buttonColorHover="#F0B960"
      linkTo="fun-car"
    />
    <div className={common["txt-header-cv"]}>Interested in hiring me?</div>
    <div className={common["txt-description-cv"]}>here is my resume</div>
    <a href={CV} target="_blank" rel="noreferrer" className={styles["cv"]}>
      <FontAwesomeIcon
        style={{ marginRight: "5px" }}
        size="2x"
        icon={faFilePdf}
      />
      <div className={styles["cv-text"]}>CV</div>
    </a>
  </div>
);

export default Main;
