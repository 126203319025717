import { Route, Routes } from "react-router-dom";
import FunCar from "./pages/funCar";
import Main from "./pages/main";
import Coworking from "./pages/coworking";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import styles from "./App.module.scss";
import common from "./common.module.css";
import Autoreport from "./pages/autoreport";

function App() {
  return (
    <div className={styles.app}>
      <div
        className={classNames({
          [styles["wrap"]]: !isMobile,
          [styles["wrap-mobile"]]: isMobile,
        })}
      >
        <Routes>
          <Route index element={<Main />} />
          <Route path="autoreport" element={<Autoreport />} />
          <Route path="fun-car" element={<FunCar />} />
          <Route path="coworking" element={<Coworking />} />
        </Routes>
      </div>
      <div className={styles.footer}>
        <div className={common["txt-header-cv"]}>Let's stay in touch!</div>
        <div className={common["txt-description-cv"]}>olg.janik@gmail.com</div>
      </div>
    </div>
  );
}

export default App;
